import React, {useEffect, useState} from "react"
import {useParallax} from "react-scroll-parallax"

import Footer from "../Footer/Footer"

import "./About.scss"


function About() {
  
  const title = useParallax({translateY: [-50, 0], opacity: [0, 1], shouldAlwaysCompleteAnimation: true})
  const texte = useParallax({opacity: [0, 1], translateY: [50, 0], shouldAlwaysCompleteAnimation: true})

  const [lang, setLang] = useState("fr")

  useEffect(() => {
    let current = 1

    const interval = setInterval(() => {
      let prev = current === 0 ? 10 : current - 1
      document.querySelector(`.About-Words--${current}`).classList.add("active")
      document.querySelector(`.About-Words--${prev}`).classList.remove("active")
      current = current === 10 ? 0 : current + 1
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  const FR_texte = () => {
    return <span>
      {`Je m'appelle Johann, je suis Front Developer depuis 10 ans en studio digital et en agence. J’aime réaliser des projets créatifs, modernes et performants en m’inspirant des dernières trends tech et design.`}
      <br />
      {`Je suis actuellement à Lyon où je travaille pour `}
      <a href="https://www.insign.fr/" rel="noreferrer" target="_blank">Insign</a>
      <br /><br />
      {`N'hésitez pas à me contacter pour parler de `}
      <span className="About-Wordslider" role="button" tabIndex="0" onClick={() => window.location.href = "mailto:address@dmail.com"}>
        {Wordslider(FR_wordlist)}
      </span>
    </span>
  }

  const EN_texte = () => {
    return <span>
      {`My name is Johann, I have been a Front Developer for 10 years in digital studio and agency. I like to carry out creative, modern and high-performance projects inspired by the latest tech and design trends.`}
      <br />
      {`I am currently in Lyon where I work for `}
      <a href="https://www.insign.fr/" rel="noreferrer" target="_blank">Insign</a>
      <br /><br />
      {`Feel free to contact me to discuss about `}
      <span className="About-Wordslider" role="button" tabIndex="0" onClick={() => window.location.href = "mailto:address@dmail.com"}>
        {Wordslider(FR_wordlist)}
      </span>
    </span>
  }

  const FR_wordlist = ["React","Foot","Vue","Surf","JS","Next","IA","F1","WebGL","API","Nuxt"]

  const Wordslider = (list) => {
    return list.map(
      (x, index) => {return <span key={x} className={`About-Words About-Words--${index} ${index === 0 ? "active" : ""} ${index % 2 == 0 ? "even" : ""}`}>{x}</span>}
    )
  }
  
  return (
    <div className="About enter">

      <div className="About-lang">
        <span
          className={lang === "fr" ? "active" : ""}
          role="button"
          tabIndex={0}
          onClick={() => setLang("fr")}>
          FR
        </span>
        <span
          className={lang === "en" ? "active" : ""}
          role="button"
          tabIndex={0}
          onClick={() => {setLang("en")}}>
          EN
        </span>
      </div>

      <div className="About-wrapper">
        <p ref={title.ref} className="About-hello">{lang === "fr" ? "Bonjour" : "Hello"}</p>
        <p ref={texte.ref} className="About-texte">
          {lang === "fr" ? FR_texte() : EN_texte()}
        </p>
      </div>
      <Footer lang={lang} setLang={setLang}/>
    </div>
  )
}

export default About
