import React from "react"
import {useParallax} from "react-scroll-parallax"
import {string, func} from "prop-types"

import "./Footer.scss"

import icon from "../../assets/file-download-line.svg"

Footer.propTypes = {
  lang    : string.isRequired,
  setLang : func.isRequired
}

function Footer({lang, setLang}) {
  const footer = useParallax({
    scale                         : [0.85, 1],
    shouldAlwaysCompleteAnimation : true,
  })

  return (
    <div className='Footer'>
      <div ref={footer.ref} className='Footer-wrapper'>
        <ul className='Footer-icons'>
          <li className='Footer-icons--round' onClick={() => window.open("https://www.linkedin.com/in/johann-jeannelle/", "_blank")}>
            <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path d='M6.94048 4.99993C6.94011 5.81424 6.44608 6.54702 5.69134 6.85273C4.9366 7.15845 4.07187 6.97605 3.5049 6.39155C2.93793 5.80704 2.78195 4.93715 3.1105 4.19207C3.43906 3.44699 4.18654 2.9755 5.00048 2.99993C6.08155 3.03238 6.94097 3.91837 6.94048 4.99993ZM7.00048 8.47993H3.00048V20.9999H7.00048V8.47993ZM13.3205 8.47993H9.34048V20.9999H13.2805V14.4299C13.2805 10.7699 18.0505 10.4299 18.0505 14.4299V20.9999H22.0005V13.0699C22.0005 6.89993 14.9405 7.12993 13.2805 10.1599L13.3205 8.47993Z'></path>
            </svg>
          </li>
          <li className='Footer-icons--round' onClick={() => window.location.href = "mailto:address@dmail.com"}>
            <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path d='M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM20 7.23792L12.0718 14.338L4 7.21594V19H20V7.23792ZM4.51146 5L12.0619 11.662L19.501 5H4.51146Z'></path>
            </svg>
          </li>
          <li className='Footer-icons--button' onClick={() => window.open("https://www.johannjeannelle.com/media/Johann-JEANNELLE-CV.pdf", "_blank")}>
            <img alt="download" src={icon}/>
            <p>C.V</p>
          </li>
        </ul>
        <div className="Footer-lang">
          <span
            className={lang === "fr" ? "active" : ""}
            role="button"
            tabIndex={0}
            onClick={() => setLang("fr")}>
            FR
          </span>
          <span
            className={lang === "en" ? "active" : ""}
            role="button"
            tabIndex={0}
            onClick={() => {setLang("en")}}>
            EN
          </span>
        </div>
        <p className='Footer-copyright'>© Johann JEANNELLE - 2023</p>
      </div>
    </div>
  )
}

export default Footer
