import React, {useRef, useState, useEffect, Suspense} from "react"
import * as THREE from "three"
import {Canvas, useFrame} from "@react-three/fiber"
import {Edges, useGLTF, ContactShadows} from "@react-three/drei"
import {LayerMaterial, Depth, Fresnel} from "lamina"

import {object} from "prop-types"

import Logo3D from "../../obj/JJ.glb"


import "./Scene.scss"

function Scene() {

  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)


  let lastScale = 1

  useEffect(() => {
    styleCanvas()
    document.addEventListener("scroll", styleCanvas)
  }, [])
  
  function styleCanvas(s) {
    const percentA = window.scrollY * 100 / window.innerHeight
    let scale = (100 - percentA) / 100


    if(scale < .25){scale = .25}

    function lerp(a, b, alpha) {
      return a + alpha * (b - a)
    }


    document.querySelector(".Scene").querySelector("canvas").style.transform = `translateX(-50%) scale(${lerp(scale, lastScale, .5)})`

    lastScale = scale

  }
  
  return (
    <div className="Scene enter" >
      <Canvas
        shadows
        camera={{position: [0, 1, 0], fov: 60}}
      >
        <Suspense fallback={null}>
          <Rig>
            <Logo/>
          </Rig>
          <ContactShadows blur={.2} color="#000" far={25} opacity={1} position={[-.025, -0.5, 0]} resolution={1024} scale={40}/>
        </Suspense>
      </Canvas>
    </div>
  )
}

Rig.propTypes = {
  children : object
}

function Rig({children}) {

  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)

  useEffect(() => {
    if (window.DeviceOrientationEvent && "ontouchstart" in window) {
      window.addEventListener("deviceorientation", function (eventData) {
        var tiltX = Math.round(eventData.gamma * 4)
        var tiltY =  Math.round(eventData.beta * 2)
        setRotation(tiltX,tiltY)
      }, false)
    }else {
      document.addEventListener("mousemove", setMouse)
    }
  }, [])

  const ref = useRef()
  const vec = new THREE.Vector3()

  function setMouse(e) {
    setMouseX((e.clientX - (window.innerWidth / 2)) * 100 / (window.innerWidth / 2) / 100)
    setMouseY((e.clientY - (window.innerHeight / 2)) * 100 / (window.innerHeight / 2) / 100)
  }

  function setRotation(tiltX, tiltY) {
    setMouseX((((tiltX * 100) / 360) / 100)*2)
    // setMouseY(((((tiltY * 100) / 360) / 100)*2.5) - 1)
  }

  useFrame(() => {
    ref.current.position.lerp(vec.set(mouseX * 0.1, mouseY * 0.1, 0), 0.1)
    ref.current.rotation.z = THREE.MathUtils.lerp(ref.current.rotation.z, (-mouseX * Math.PI) / 5, 0.1)
    ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, (mouseY * Math.PI) / 4, 0.1)
  })
  return <group ref={ref}>{children}</group>
}

function Logo() {
  const mesh = useRef()
  const ref = useRef()

  const {nodes} = useGLTF(Logo3D)
  const [r] = useState(() => Math.random() * 10000)

  const [mobile] = useState(window.innerWidth < 650)

  

  useFrame((state) => {
    const sin = Math.sin(state.clock.elapsedTime / 2)
    const cos = Math.cos(state.clock.elapsedTime / 2)
    mesh.current.layers[0].origin.set(cos / 2, 0, 0)
    mesh.current.layers[1].origin.set(cos, sin, cos)
    mesh.current.layers[2].origin.set(sin, cos, sin)
    mesh.current.layers[3].origin.set(cos, sin, cos)

    ref.current.position.y = (Math.sin(state.clock.elapsedTime + r) / 10)
  })

  return (
    <group ref={ref} dispose={null} scale={mobile ? .7 : 1}>
      <mesh castShadow geometry={nodes.Curve.geometry} position={[-.345, 0, .35]} rotation={[0, 0, 0]} scale={5}>
        <LayerMaterial ref={mesh} attach="material" oneMapped={false}>
          <Depth alpha={1} colorA="#ff0080" colorB="black" far={0.5} mode="normal" near={0.5 * 0.7} origin={[0, 0, 0]} />
          <Depth alpha={1} colorA="blue" colorB="#f7b955" far={2} mode="add" near={2 * 0.7} origin={[0, 1, 1]} />
          <Depth alpha={1} colorA="green" colorB="#f7b955" far={3} mode="add" near={3 * 0.7} origin={[0, 1, -1]} />
          <Depth alpha={1} colorA="white" colorB="red" far={1.5} mode="overlay" near={1.5 * 0.7} origin={[1, -1, -1]} />
          <Fresnel bias={0.05} color="white" intensity={0.5} mode="add" power={1.5} />
        </LayerMaterial>
        <Edges color="white" />
      </mesh>
    </group>
  )
}

export default Scene