import React from "react"
import {useParallax} from "react-scroll-parallax"

import "./Name.scss"

function Name() {
  const first = useParallax({translateY: [0, -50], opacity: [1, 0], shouldAlwaysCompleteAnimation: true})
  const last = useParallax({opacity: [1, 0], translateY: [0, 50], shouldAlwaysCompleteAnimation: true})

  return (
    <div className="Name enter">
      <div className="Name-wrapper">
        <p ref={first.ref} className="Name-first">JOHANN</p>
        <p ref={last.ref} className="Name-last">JEANNELLE</p>
      </div>
    </div>
  )
}

export default Name
