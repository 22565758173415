import React, {useEffect, useState} from "react"
import {ParallaxProvider} from "react-scroll-parallax"

import Scene from "./components/Scene/Scene"
import Name from "./components/Name/Name"
import About from "./components/About/About"

function App() {

  const [ready, setReady] = useState(false)

  useEffect(() => {

    
    setTimeout(() => {
      setReady(true)
      window.scrollTo(0,0)
    }, 100)

    setTimeout(() => {
      document.querySelector(`.Scene`).classList.remove("enter")
      document.querySelector(`.Name`).classList.remove("enter")
      document.querySelector(`.About`).classList.remove("enter")
    }, 1000)
  }, [])

  return (
    ready ?
      <ParallaxProvider>
        <div className="App">
          <Name />
          <Scene />
          <About />
        </div>
      </ParallaxProvider> : null
  )
}

export default App
